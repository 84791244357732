import Section from '@babylon/ui-kit-structures/components/others/section';
import styled from 'styled-components';

export const Main = styled.main``;

export const HeaderCarousel = styled(Section)`
	margin-bottom: 0;
`;

export const Searcher = styled(Section)`
	margin-top: -50px;
	margin-bottom: 0;
`;

export const BreadCrumb = styled(Section)``;

export const Masonry = styled(Section)``;

export const Features = styled(Section)``;

export const CarouselLogos = styled(Section)``;

export const VerticalTabs = styled(Section)``;

export const CarouselCardSimple = styled(Section)``;

export const LinkSEO = styled(Section)``;

export const Blog = styled(Section)``;

export const CarouselProductCard = styled(Section)``;

export const TabsCarouselProductCard = styled(Section)`
	margin-bottom: 0;
`;
