import type { CarouselCardSimpleProps } from '@babylon/ui-kit-carousels/components/carousel-card-simple/types';
import CarouselCardSimple from '@babylon/ui-kit-carousels/components/carousel-card-simple/views/desktop';
import type { CarouselHeaderProps } from '@babylon/ui-kit-carousels/components/carousel-header/types';
import CarouselHeader from '@babylon/ui-kit-carousels/components/carousel-header/views/desktop';
import type { BreadcrumbsProps } from '@babylon/ui-kit-common/components/others/breadcrumbs/types';
import Breadcrumbs from '@babylon/ui-kit-common/components/others/breadcrumbs/views/desktop';
import type { LinkSEOProps } from '@babylon/ui-kit-common/components/others/links-seo/types';
import LinksSEO from '@babylon/ui-kit-common/components/others/links-seo/views/desktop';
import ScrollToTop from '@babylon/ui-kit-common/components/others/scroll-to-top';
import withHydrationOnDemand from '@babylon/ui-kit-hocs/hydration-on-demand/withHydrationOnDemand';
import SearcherFli from '@babylon/ui-kit-searchers/components/searchers/searcher-fli';
import type {
	OldSearcherFliMobileProps,
	SearcherFliMobileProps,
	SearcherFliProps,
} from '@babylon/ui-kit-searchers/components/searchers/searcher-fli/types';
import { Container } from '@babylon/ui-kit-styles/common/mixins/container.styled';
import BlogTransports from '@babylon/ui-kit-wrappers/components/transports/blog/views/desktop';
import CarouselLogosTransports from '@babylon/ui-kit-wrappers/components/transports/carousel-logos/views/desktop';
import CarouselProductTransports from '@babylon/ui-kit-wrappers/components/transports/carousel-product/views/desktop';
import FeaturesTransports from '@babylon/ui-kit-wrappers/components/transports/features/views/desktop';
import MasonryCarouselTransports from '@babylon/ui-kit-wrappers/components/transports/masonry/views/desktop';
import TabsCarouselProductCardTransports from '@babylon/ui-kit-wrappers/components/transports/tabs-carousel-product-card/views/desktop';
import VerticalTabsTransports from '@babylon/ui-kit-wrappers/components/transports/vertical-tabs/views/desktop';
import type { BlogTransportsDesktopProps } from '@babylon/ui-kit-wrappers/helpers/transports/blog/desktop/types';
import type { FeatureListTransportsProps } from '@babylon/ui-kit-wrappers/helpers/transports/card-feature-list/types';
import type { CarouselLogosTransportsDesktopProps } from '@babylon/ui-kit-wrappers/helpers/transports/carousel-logos/desktop/types';
import type { MasonryCarouselTransportsProps } from '@babylon/ui-kit-wrappers/helpers/transports/carousel-masonry/desktop/types';
import type { CarouselModel } from '@babylon/ui-kit-wrappers/helpers/transports/carousel-product/desktop/types';
import type { TransportsTabsCarouselProductCardProps } from '@babylon/ui-kit-wrappers/helpers/transports/tabs-product/desktop/types';
import type { VerticalTabsTransportsDesktopProps } from '@babylon/ui-kit-wrappers/helpers/transports/vertical-tabs/desktop/types';
import type { FC } from 'react';

import type { MainHomeProps } from '../../types';
import * as S from './styled';

const CarouselHeaderHydration = withHydrationOnDemand<CarouselHeaderProps>()(CarouselHeader);
const SearcherFliHydration = withHydrationOnDemand<
	SearcherFliProps | SearcherFliMobileProps | OldSearcherFliMobileProps
>()(SearcherFli);
const BreadcrumbsHydration = withHydrationOnDemand<BreadcrumbsProps>()(Breadcrumbs);
const MasonryCarouselHydration = withHydrationOnDemand<MasonryCarouselTransportsProps>()(MasonryCarouselTransports);
const FeaturesHydration = withHydrationOnDemand<FeatureListTransportsProps>()(FeaturesTransports);
const CarouselLogosHydration = withHydrationOnDemand<CarouselLogosTransportsDesktopProps>()(CarouselLogosTransports);
const VerticalTabsHydration = withHydrationOnDemand<VerticalTabsTransportsDesktopProps>()(VerticalTabsTransports);
const BlogHydration = withHydrationOnDemand<BlogTransportsDesktopProps>()(BlogTransports);
const LinksSEOHydration = withHydrationOnDemand<LinkSEOProps>()(LinksSEO);
const CarouselCardSimpleHydration = withHydrationOnDemand<CarouselCardSimpleProps>()(CarouselCardSimple);
const TabsCarouselProductCardHydration = withHydrationOnDemand<TransportsTabsCarouselProductCardProps>()(
	TabsCarouselProductCardTransports
);
const CarouselProductCardHydration = withHydrationOnDemand<CarouselModel>()(CarouselProductTransports);

export const MainHomeDesktop: FC<MainHomeProps> = ({ props }) => {
	const {
		HeaderCarouselRxjs,
		BreadcrumbsNavRxjs,
		LogosCarousel,
		BlogAub,
		LinksSeoAub,
		CarouselCardSimpleAub,
		CardProductCarouselTabs,
		MasonryCarousel,
		SearcherFLi: SearcherFLiData,
		CardFeatureList,
		CardProductCarousel,
		VerticalTabs,
	} = props;
	return (
		<S.Main data-testid='MainHome'>
			{!!HeaderCarouselRxjs && (
				<S.HeaderCarousel>
					<CarouselHeaderHydration props={HeaderCarouselRxjs} />
				</S.HeaderCarousel>
			)}
			{!!SearcherFLiData && (
				<S.Searcher>
					<Container>
						<SearcherFliHydration props={SearcherFLiData} />
					</Container>
				</S.Searcher>
			)}
			{!!BreadcrumbsNavRxjs && (
				<S.BreadCrumb>
					<Container>
						<BreadcrumbsHydration props={BreadcrumbsNavRxjs} />
					</Container>
				</S.BreadCrumb>
			)}
			{!!CardProductCarousel && (
				<S.CarouselProductCard>
					<Container>
						<CarouselProductCardHydration isFirstComponent {...CardProductCarousel} />
					</Container>
				</S.CarouselProductCard>
			)}
			{!!CardProductCarouselTabs && (
				<S.TabsCarouselProductCard>
					<Container>
						<TabsCarouselProductCardHydration {...CardProductCarouselTabs} />
					</Container>
				</S.TabsCarouselProductCard>
			)}
			{!!MasonryCarousel && (
				<S.Masonry>
					<Container>
						<MasonryCarouselHydration {...MasonryCarousel} />
					</Container>
				</S.Masonry>
			)}
			{!!CardFeatureList && (
				<S.Features>
					<FeaturesHydration {...CardFeatureList} />
				</S.Features>
			)}
			{!!CarouselCardSimpleAub && CarouselCardSimpleAub.items.length > 0 && (
				<S.CarouselCardSimple>
					<Container>
						<CarouselCardSimpleHydration props={CarouselCardSimpleAub} />
					</Container>
				</S.CarouselCardSimple>
			)}
			{!!LogosCarousel && LogosCarousel.items.length > 0 && (
				<S.CarouselLogos>
					<Container>
						<CarouselLogosHydration {...LogosCarousel} />
					</Container>
				</S.CarouselLogos>
			)}
			{!!VerticalTabs && (
				<S.VerticalTabs>
					<Container>
						<VerticalTabsHydration {...VerticalTabs} preRenderTabs />
					</Container>
				</S.VerticalTabs>
			)}
			{!!BlogAub && (
				<S.Blog>
					<Container>
						<BlogHydration {...BlogAub} />
					</Container>
				</S.Blog>
			)}
			{!!LinksSeoAub && (
				<S.LinkSEO>
					<Container>
						<LinksSEOHydration props={LinksSeoAub} />
					</Container>
				</S.LinkSEO>
			)}
			<ScrollToTop />
		</S.Main>
	);
};

export default MainHomeDesktop;
