import { DEVICES } from '@babylon/ui-kit-context/types';
import { useDevice } from '@babylon/ui-kit-hooks/useDevice';
import type { FC } from 'react';

import type { MainHomeMobileProps, MainHomeProps } from './types';
import views from './views';

export const MainHome: FC<MainHomeProps | MainHomeMobileProps> = props => {
	const device: DEVICES = useDevice();
	const isMobile = device === DEVICES.mobile;
	const View = views[device] || views[DEVICES.desktop];
	const viewProps = isMobile ? (props as MainHomeMobileProps) : (props as MainHomeProps);

	return <View {...viewProps} />;
};

export * from './types';
export default MainHome;
