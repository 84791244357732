import type { ContextBabylonProps } from '@babylon/ui-kit-context/types';
import BaseTemplateLogitravel from '@babylon/ui-kit-layout/components/logitravel/base-template';
import type { FC } from 'react';

import type { MainHomeMobileProps, MainHomeProps } from '@/main/flights/home';
import MainHome from '@/main/flights/home';
import { renderPages } from '@/sites/common';

export const HomeComponent: FC<{
	root: MainHomeProps | MainHomeMobileProps;
	context: ContextBabylonProps;
}> = props => {
	const { root, context } = props;
	const { Header, Footer } = root.props;
	const templateProps = {
		context: context,
		header: Header,
		footer: Footer,
		mHeader: Header,
		mFooter: Footer,
	};
	return (
		<BaseTemplateLogitravel {...templateProps}>
			<MainHome {...root} />
		</BaseTemplateLogitravel>
	);
};

export const render = renderPages(HomeComponent);

export default HomeComponent;
