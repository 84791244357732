import type { MobileBannersProps } from '@babylon/ui-kit-banners/components/banners/types';
import Banners from '@babylon/ui-kit-banners/components/banners/views/mobile';
import Title from '@babylon/ui-kit-base/components/text/title/views/mobile';
import type { BreadcrumbsProps } from '@babylon/ui-kit-common/components/others/breadcrumbs/types';
import Breadcrumbs from '@babylon/ui-kit-common/components/others/breadcrumbs/views/mobile';
import type { LinkSEOProps } from '@babylon/ui-kit-common/components/others/links-seo/types';
import LinksSEO from '@babylon/ui-kit-common/components/others/links-seo/views/mobile';
import Masonry from '@babylon/ui-kit-common/components/others/masonry/views/mobile';
import type { MasonryMobileProps } from '@babylon/ui-kit-common/components/others/masonry/views/mobile/types';
import type { VerticalTabsMobileProps } from '@babylon/ui-kit-common/components/others/vertical-tabs/types';
import VerticalTabs from '@babylon/ui-kit-common/components/others/vertical-tabs/views/mobile';
import withHydrationOnDemand from '@babylon/ui-kit-hocs/hydration-on-demand/withHydrationOnDemand';
import SearcherFli from '@babylon/ui-kit-searchers/components/searchers/searcher-fli';
import type {
	OldSearcherFliMobileProps,
	SearcherFliMobileProps,
	SearcherFliProps,
} from '@babylon/ui-kit-searchers/components/searchers/searcher-fli/types';
import CardsScroll from '@babylon/ui-kit-structures/components/others/cards-scroll';
import type { CardsScrollProps } from '@babylon/ui-kit-structures/components/others/cards-scroll/types';
import BlogTransports from '@babylon/ui-kit-wrappers/components/transports/blog/views/mobile';
import CarouselLogosTransports from '@babylon/ui-kit-wrappers/components/transports/carousel-logos/views/mobile';
import CarouselProductTransports from '@babylon/ui-kit-wrappers/components/transports/carousel-product/views/mobile';
import FeaturesTransports from '@babylon/ui-kit-wrappers/components/transports/features/views/mobile';
import TabsCarouselProductCardTransports from '@babylon/ui-kit-wrappers/components/transports/tabs-carousel-product-card/views/mobile';
import type { BlogTransportsMobileProps } from '@babylon/ui-kit-wrappers/helpers/transports/blog/mobile/types';
import type { FeatureListTransportsProps } from '@babylon/ui-kit-wrappers/helpers/transports/card-feature-list/types';
import type { CarouselLogosTransportsDesktopProps } from '@babylon/ui-kit-wrappers/helpers/transports/carousel-logos/desktop/types';
import type { CarouselLogosTransportsMobileProps } from '@babylon/ui-kit-wrappers/helpers/transports/carousel-logos/mobile/types';
import type { TransportCarouselMobile } from '@babylon/ui-kit-wrappers/helpers/transports/carousel-product/mobile/types';
import type { TransportsTabsCarouselProductCardMobileProps } from '@babylon/ui-kit-wrappers/helpers/transports/tabs-product/mobile/types';
import _merge from 'lodash/merge';
import type { FC } from 'react';

import type { MainHomeProps } from '../../types';
import * as S from './styled';

const SearcherFliHydration = withHydrationOnDemand<
	SearcherFliProps | SearcherFliMobileProps | OldSearcherFliMobileProps
>()(SearcherFli);
const BreadcrumbsHydration = withHydrationOnDemand<BreadcrumbsProps>()(Breadcrumbs);
const CarouselProductCardHydration = withHydrationOnDemand<TransportCarouselMobile>()(CarouselProductTransports);
const CardsScrollHydration = withHydrationOnDemand<CardsScrollProps>({ on: ['scroll', 'visible'] })(CardsScroll);
const FeaturesHydration = withHydrationOnDemand<FeatureListTransportsProps>({ on: ['scroll', 'visible'] })(
	FeaturesTransports
);
const LinksSEOHydration = withHydrationOnDemand<LinkSEOProps>({ on: ['scroll', 'visible'] })(LinksSEO);
const VerticalTabsHydration = withHydrationOnDemand<VerticalTabsMobileProps>({ on: ['scroll', 'visible'] })(
	VerticalTabs
);
const BannersHydration = withHydrationOnDemand<MobileBannersProps>({ on: ['scroll', 'visible'] })(Banners);
const BlogHydration = withHydrationOnDemand<BlogTransportsMobileProps>({ on: ['scroll', 'visible'] })(BlogTransports);
const TabsCarouselProductCardHydration = withHydrationOnDemand<TransportsTabsCarouselProductCardMobileProps>({
	on: ['scroll', 'visible'],
})(TabsCarouselProductCardTransports);
const MasonryCarouselHydration = withHydrationOnDemand<MasonryMobileProps>({ on: ['scroll', 'visible'] })(Masonry);
const CarouselLogosHydration = withHydrationOnDemand<
	CarouselLogosTransportsMobileProps | CarouselLogosTransportsDesktopProps
>({ on: ['scroll', 'visible'] })(CarouselLogosTransports);

export const MainHomeDesktop: FC<MainHomeProps> = ({ props }) => {
	const {
		mSearcherFliRxjs,
		mBreadcrumbs,
		mCardProductListTabs,
		mCardImageCarousel,
		mLogosCarousel,
		mCardCampaignListAub,
		mBannerCarouselAub,
		mFeaturesAub,
		mBlog,
		mCollapseList,
		mLinksSeoAub,
		mCardProductCarousel,
	} = props;

	props.mBreadcrumbs = _merge(mBreadcrumbs, {
		config: {
			isHome: false,
		},
	});

	return (
		<S.Main data-testid='MainHome'>
			{!!mSearcherFliRxjs && (
				<S.Searcher>
					<Title tag='h1' title={mSearcherFliRxjs.title} />
					<SearcherFliHydration props={mSearcherFliRxjs} />
				</S.Searcher>
			)}
			{!!mBreadcrumbs && (
				<S.BreadCrumb>
					<BreadcrumbsHydration props={mBreadcrumbs} />
				</S.BreadCrumb>
			)}
			{!!mCardProductCarousel && (
				<S.CarouselProductCard>
					<CarouselProductCardHydration isFirstComponent {...mCardProductCarousel} />
				</S.CarouselProductCard>
			)}
			{!!mCardProductListTabs && (
				<S.TabsCarouselProductCard>
					<TabsCarouselProductCardHydration {...mCardProductListTabs} />
				</S.TabsCarouselProductCard>
			)}
			{!!mCardImageCarousel && (
				<S.CardImageCarousel>
					<MasonryCarouselHydration props={mCardImageCarousel} />
				</S.CardImageCarousel>
			)}
			{!!mLogosCarousel && (
				<S.CarouselLogos>
					<CarouselLogosHydration {...mLogosCarousel} />
				</S.CarouselLogos>
			)}
			{!!mCardCampaignListAub && (
				<S.CarouselCardSimple>
					<CardsScrollHydration {...mCardCampaignListAub} />
				</S.CarouselCardSimple>
			)}
			{!!mBannerCarouselAub && (
				<S.Banners>
					<BannersHydration props={mBannerCarouselAub} />
				</S.Banners>
			)}
			{!!mFeaturesAub && (
				<S.Features>
					<FeaturesHydration {...mFeaturesAub} />
				</S.Features>
			)}
			{!!mBlog && (
				<S.Blog>
					<BlogHydration {...mBlog} />
				</S.Blog>
			)}
			{!!mCollapseList && (
				<S.CollapseList>
					<VerticalTabsHydration props={{ ...mCollapseList, preRenderTabs: true }} />
				</S.CollapseList>
			)}
			{!!mLinksSeoAub && (
				<S.LinkSEO>
					<LinksSEOHydration props={mLinksSeoAub} />
				</S.LinkSEO>
			)}
		</S.Main>
	);
};

export default MainHomeDesktop;
