import { DEVICES } from '@babylon/ui-kit-context/types';
import type { VIEWS } from '@babylon/ui-kit-hooks/useDevice';

import MainHomeDesktop from './desktop';
import MainHomeMobile from './mobile';

const views: VIEWS = {
	[DEVICES.desktop]: MainHomeDesktop,
	[DEVICES.mobile]: MainHomeMobile,
};

export default views;
