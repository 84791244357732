import { TitleMobileStyled, TitleMobileWrapper } from '@babylon/ui-kit-base/components/text/title/views/mobile/styled';
import Section from '@babylon/ui-kit-structures/components/others/section';
import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const Main = styled.main``;

export const Searcher = styled(Section)`
	margin-top: 0;
	margin-bottom: 0;
	background-color: ${({ theme }) => theme.colors.primary.base};
	${TitleMobileWrapper} {
		padding-top: 12px;
		margin-bottom: 0px;
	}
	${TitleMobileStyled} {
		${FontMixin({ size: 'large', height: 'large' })}
		color: ${({ theme }) => theme.colors.white.base};
	}
`;

export const BreadCrumb = styled(Section)`
	margin-top: 0;
`;

export const CarouselProductCard = styled(Section)`
	margin-bottom: 0;
`;

export const TabsCarouselProductCard = styled(Section)`
	margin-bottom: 0;
	${TitleMobileStyled} {
		padding: 0;
	}
`;

export const CardImageCarousel = styled(Section)`
	border-top: 1px solid ${({ theme }) => theme.colors.grays.light};
	padding-top: 12px;
	margin-bottom: 20px;
`;

export const CarouselLogos = styled(Section)`
	background-color: ${({ theme }) => theme.colors.white.base};
	border-top: 1px solid ${({ theme }) => theme.colors.grays.light};
	border-bottom: 1px solid ${({ theme }) => theme.colors.grays.light};
	padding: 20px 0;
	margin-bottom: 20px;
`;

export const CarouselCardSimple = styled(Section)``;

export const Banners = styled(Section)``;

export const Features = styled(Section)``;

export const Blog = styled(Section)``;

export const CollapseList = styled(Section)``;

export const LinkSEO = styled(Section)``;
